'use client'

import type { Article, Articles } from '@eo/graphql-types'

import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'

import { constants } from '@/constants'
import FeaturedArticleUi from './FeaturedArticleUi'

const GET_ARTICLES = gql(`
  query GetFeaturedArticles($limit: Int, $skip: Int, $where: ArticlesWhereInput, $preview: Boolean, $sort: String) {
    previewArticles: articles(limit: $limit, skip: $skip, where: $where, preview: $preview, sort: $sort) {
      items {
        id
        slug
        title
        subtitle
        publishOn
        hero {
          author
          description
          cdnFiles {
            url
          }
        }
        intro
        content {
          ... on QuoteElement {
            _id
            type
            quote
            author
            source
          }
          ... on QuoteReference {
            quoteModel {
              _id
              type
              quote
              author
              source
            }
          }
        }
      }
    }
}
`)

type Props = {
  defaultArticle: Article
}

export default function PreviewFeaturedArticle({ defaultArticle }: Props) {
  const [fetchPreview, { data }] = useLazyQuery<{ previewArticles: Articles }>(GET_ARTICLES, {
    variables: {
      limit: 1,
      where: {
        includedDomains: [constants.preprEnvironments.dit],
        anyChannels: [
          'b27d8801-46fb-4415-82ff-9cfcf910c5c0', // 'Uitgelicht op homepage'
        ],
      },
      sort: 'changed_on_DESC',
    },
    errorPolicy: 'all',
  })

  const article = data?.previewArticles?.items?.[0] ?? defaultArticle
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const preview = urlParams.get('preview') === '1'

    if (preview) {
      fetchPreview({
        variables: {
          preview,
        },
      }).catch(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FeaturedArticleUi article={article} />
}
