'use client'

import type { Article, Articles } from '@eo/graphql-types'

import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { LoadButton, ReferenceArticle } from '@eo/next-ui'

import { constants } from '@/constants'
import { filterUnique } from '@/utils'
import GET_ARTICLES from './GetArticles.graphql'

export type Props = {
  articles: Article[]
  total: number
  skip: number
}

const mergeArticles = (prevArticles: Article[], input: Article[]) =>
  filterUnique([...prevArticles, ...input], (article) => article?.id || '')

export default function AllArticlesList({
  articles: currentArticles,
  total: currentTotal,
  skip,
}: Props) {
  const [articles, setArticles] = useState<Article[]>(currentArticles)
  const [total, setTotal] = useState<number>(currentTotal)
  const [fetchArticles, { data, loading }] = useLazyQuery<{ articles: Articles }>(GET_ARTICLES, {
    variables: {
      limit: skip + 10,
      where: {
        includedDomains: [constants.preprEnvironments.dit],
      },
    },
  })

  useEffect(() => {
    if (data?.articles?.items) {
      setArticles((prev) => mergeArticles(prev, data.articles.items ?? []))
    }
    if (data?.articles?.total) {
      setTotal(data.articles.total)
    }
  }, [data])

  const fetchMore = () =>
    fetchArticles({
      variables: {
        skip: skip + articles.length,
      },
    })

  const hasMoreArticles = total > skip + articles.length

  return (
    <div className="container mx-auto my-14 flex flex-col justify-start px-4 md:px-8 lg:my-20">
      <h3 className="mb-6 text-4xl/none">Alle verhalen</h3>
      <ul className="grid grid-cols-1 gap-4 md:gap-y-8 lg:grid-cols-2">
        {articles.map((article) => (
          <li
            key={article.id}
            className="w-full grow"
          >
            <ReferenceArticle
              article={article}
              smallerImage
            />
          </li>
        ))}
      </ul>
      {hasMoreArticles && (
        <LoadButton
          loading={loading}
          className="mx-auto mt-12"
          label="Meer verhalen"
          size="lg"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => fetchMore()}
        />
      )}
    </div>
  )
}
