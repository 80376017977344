'use client'

import { useEffect, useState } from 'react'
import { Icon, iconMessage, iconPoll, iconSmileyHappyOutline } from '@eo/next-ui'
import { useIntersectionObserver } from '@uidotdev/usehooks'
import clsx from 'clsx/lite'

type Props = Omit<React.ComponentProps<'div'>, 'ref'> & {
  articleId: string | null
}

export default function InteractionCountLabels({ articleId, className, ...extraProps }: Props) {
  const [comments, setComments] = useState<number | null>(null)
  const [reactions, setReactions] = useState<number | null>(null)
  const [pollResponses, setPollResponses] = useState<number | null>(null)
  const [isFetchedOnce, setFetchedOnce] = useState(false)

  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  })

  useEffect(() => {
    if (!entry?.isIntersecting || isFetchedOnce) return
    fetch(`/api/interaction-counts/${articleId}`)
      .then((response) => response.json())
      .then((body: { comments?: number; reactions?: number; pollResponses?: number }) => {
        if (body.comments) {
          setComments(body.comments)
        }
        if (body.reactions) {
          setReactions(body.reactions)
        }
        if (body.pollResponses) {
          setPollResponses(body.pollResponses)
        }
      })
      // Error is already notices at the API level
      .catch(() => {})
      .finally(() => setFetchedOnce(true))
  }, [articleId, entry, isFetchedOnce])

  return (
    <div
      ref={ref}
      className={clsx(
        'flex w-fit cursor-pointer gap-4 rounded-full text-xs/none text-purple-700 transition-opacity duration-500',
        !(reactions || comments) && 'pointer-events-none opacity-0',
        className,
      )}
      {...extraProps}
    >
      {!!reactions && (
        <p className="flex items-center justify-center gap-1 text-xs/tight font-bold">
          <Icon
            size={16}
            icon={iconSmileyHappyOutline}
          />
          {reactions}
        </p>
      )}
      {!!comments && (
        <p className="flex items-center justify-center gap-1 text-xs/tight font-bold">
          <Icon
            size={16}
            icon={iconMessage}
          />
          {comments}
        </p>
      )}
      {!!pollResponses && (
        <p className="flex items-center justify-center gap-1 text-xs/tight font-bold">
          <Icon
            size={16}
            icon={iconPoll}
          />
          {pollResponses}
        </p>
      )}
    </div>
  )
}
