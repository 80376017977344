import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/app/_components/AllArticles/AllArticlesList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/app/_components/FeaturedArticle/PreviewFeaturedArticle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/components/InteractionCountLabels.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/home/vsts/work/1/s/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["MultipleChoicePoll"] */ "/home/vsts/work/1/s/node_modules/@eo/next-ui/dist/lib/components/Poll/MultipleChoicePoll.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/@eo/next-ui/dist/lib/components/Reference/ReferenceArticle.js");
;
import(/* webpackMode: "eager", webpackExports: ["Spinner"] */ "/home/vsts/work/1/s/node_modules/@eo/next-ui/dist/lib/components/Spinner/index.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
