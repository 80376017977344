import type { Article, QuoteElement, QuoteModel, QuoteReference } from '@eo/graphql-types'

import Link from 'next/link'
import {
  Button,
  hyphenate,
  Icon,
  iconCheck,
  iconChevronRight,
  readableDate,
  SpeechBubble,
  srcSetWidths,
} from '@eo/next-ui'

import InteractionCountLabels from '@/components/InteractionCountLabels'

type Props = {
  article: Article
}

export default function FeaturedArticleUi({ article }: Props) {
  let featuredQuote: QuoteElement | QuoteModel | undefined
  featuredQuote ??= article.content?.find(
    (item): item is QuoteElement => item?.__typename === 'QuoteElement',
  )

  featuredQuote ??= article.content?.find(
    (item): item is QuoteReference => item?.__typename === 'QuoteReference',
  )?.quoteModel

  return (
    <div className="grid items-center gap-8 xl:grid-cols-2 xl:gap-x-28">
      <div className="flex max-w-xl flex-col">
        {article.subtitle && (
          <p className="mb-4 line-clamp-1 text-base/tight font-semibold text-purple-500 @lg:mb-1">
            {article.subtitle}
          </p>
        )}
        <Link href={`/artikel/${article.slug}`}>
          {article.title && (
            <p className="mb-2 hyphens-manual text-balance font-display text-4xl/none font-semibold sm:text-5xl/none md:text-6xl/none">
              {hyphenate(article.title)}
            </p>
          )}
          <div className="flex flex-wrap items-center gap-3">
            <div className="leading-loose text-gray-600">
              {readableDate(article.publishOn ?? '').full}
            </div>
            {article.id && <InteractionCountLabels articleId={article.id} />}
          </div>
          {article.intro && <p className="mt-6 hidden text-lg lg:block">{article.intro}</p>}
        </Link>
      </div>
      <Link
        href={`/artikel/${article.slug}`}
        className="relative flex items-start justify-start pt-28 xl:pt-16"
      >
        {article.hero?.[0]?.cdnFiles?.[0]?.url && (
          <img
            className="aspect-video rounded-2xl object-cover"
            alt={article.hero[0].description || 'hero'}
            srcSet={srcSetWidths(article.hero[0].cdnFiles[0].url, [343, 454, 552, 608, 704, 960])}
            sizes="(min-width: 640px) 608px, (min-width: 768px) 704px, (min-width: 1024px) 960px, (min-width: 1280px) 552px, calc(100vw - 2rem)"
          />
        )}
        {featuredQuote && (
          <blockquote className="absolute inset-y-0 start-4 w-full max-w-xs lg:-start-14">
            <SpeechBubble
              caretSide="bottom"
              caretOffset={100}
              color="gray-100"
              className="px-5 py-4"
            >
              <div className="flex items-center gap-2">
                {featuredQuote.author && (
                  <p className="inline text-lg/tight font-bold">{featuredQuote.author}</p>
                )}
                <Icon
                  icon={iconCheck}
                  size={16}
                  color="white"
                  className="rounded-full p-1"
                  style={{
                    backgroundImage: 'linear-gradient(265.8deg, #6A27FF -11.65%, #81009A 89.73%)',
                  }}
                />
              </div>
              <div className="mb-4">
                {featuredQuote.source && (
                  <p className="inline-block text-sm/tight opacity-60">{featuredQuote.source}</p>
                )}
              </div>
              <p>{featuredQuote.quote}</p>
            </SpeechBubble>
          </blockquote>
        )}
      </Link>
      <Button
        className="w-fit"
        color="gray-200"
        label="Verder lezen"
        outlined
        bgOpacity={0}
        textColor="purple-700"
        rounded
        size="md"
        tabIndex={-1}
        to={`/artikel/${article.slug}`}
      >
        Verder lezen
        <Icon
          icon={iconChevronRight}
          size={18}
        />
      </Button>
    </div>
  )
}
